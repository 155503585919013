<template>
  <div class="col-lg-4 mb-lg-0 align-self-end">
    <b-button
      v-if="checkPermission('create category')"
      v-b-modal.form-kupon-modal
      class="btn btn-info btn-sm"
      data-toggle="tooltip"
      data-placement="top"
      title="Add User"
      @click="cleanUpForm"
    >
      <feather-icon
        icon="PlusCircleIcon"
      />
      Tambah Kupon
    </b-button>

    <b-modal
      id="form-kupon-modal"
      no-close-on-backdrop
      size="lg"
      title="Tambah Kupon"
    >
      <div class="form">
        <h5>Kupon</h5>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label for="">Nama Kupon:</label>
              <validation-provider>
                <b-form-input
                  v-model="formPayloadCoupon.name"
                  :state="validationsCategory.name ? false:null"
                  placeholder="Nama"
                />
                <small
                  v-for="(validation, index) in validationsCategory.name"
                  :key="`errorName${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-12 mb-1">
            <quill-editor
              v-model="formPayloadCoupon.description"
            />
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Status Kupon: </label><br>
              <validation-provider>
                <b-form-checkbox
                  v-model="formPayloadCoupon.is_active"
                  :checked="formPayloadCoupon.is_active == '1' ? true : false"
                  name="check-button"
                  :state="validationsCategory.is_active ? false:null"
                  switch
                  inline
                >
                  Status Kupon
                </b-form-checkbox><br>
                <small
                  v-for="(validation, index) in validationsCategory.is_active"
                  :key="`errorStatus${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Gambar :</label><br>
              <validation-provider>
                <b-form-file
                  placeholder="Pilih file atau letakkan di sini..."
                  drop-placeholder="Drop file here..."
                  :state="validationsCategory.image ? false:null"
                  @change="changePhoto($event)"
                />
                <small
                  v-for="(validation, index) in validationsCategory.image"
                  :key="`errorImage${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div
            v-if="imageCoupon"
            class="col-lg-6"
          >
            <div class="form-group">
              <label
                for=""
                class="mb-1"
              >Gambar Sebelumnya:</label><br>
              <b-img
                :src="imageCoupon"
                width="70"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Minimum Transaksi:</label>
              <validation-provider>
                <b-input-group
                  prepend="Rp"
                >
                  <money
                    v-model="formPayloadCoupon.minimum_transaction"
                    placeholder="Minimum transaksi"
                    class="form-control"
                    v-bind="money"
                  />
                </b-input-group>
                <small
                  v-for="(validation, index) in validationsCategory.minimum_transaction"
                  :key="`errorMinimum${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Tipe Kupon:</label>
              <validation-provider>
                <b-form-select
                  v-model="formPayloadCoupon.type"
                >
                  <option
                    value=""
                    disabled
                  >
                    ---- Pilih Tipe Kupon ----
                  </option>
                  <option value="discount">
                    Diskon
                  </option>
                  <option value="cashback">
                    Cashback
                  </option>
                </b-form-select>
                <small
                  v-for="(validation, index) in validationsCategory.type"
                  :key="`errorType${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Nilai Kupon :</label>
              <validation-provider>
                <b-input-group
                  append="%"
                >
                  <b-form-input
                    v-model="formPayloadCoupon.amount"
                    type="number"
                    :state="validationsCategory.amount ? false:null"
                    placeholder="Nilai kupon"
                  />
                </b-input-group>
                <small
                  v-for="(validation, index) in validationsCategory.amount"
                  :key="`errorAmount${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Maksimum Diskon/Cashback:</label>
              <validation-provider>
                <b-input-group
                  prepend="Rp"
                >
                  <money
                    v-model="formPayloadCoupon.maximum_discount"
                    placeholder="Maksimum transaksi"
                    class="form-control"
                    v-bind="money"
                  />
                </b-input-group>
                <small
                  v-for="(validation, index) in validationsCategory.maximum_discount"
                  :key="`errorMaksimum${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Berlaku Mulai :</label>
              <validation-provider>
                <flat-pickr
                  v-model="formPayloadCoupon.start_from"
                  class="form-control"
                  :config="{ allowInput: true, enableTime: true, dateFormat: 'Y-m-d H:i:ss', time_24hr: true, minuteIncrement: 1 }"
                />
                <small
                  v-for="(validation, index) in validationsCategory.start_from"
                  :key="`errorstart_from${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Berlaku Sampai :</label>
              <validation-provider>
                <flat-pickr
                  v-model="formPayloadCoupon.expire_at"
                  class="form-control"
                  :config="{ allowInput: true, enableTime: true, dateFormat: 'Y-m-d H:i:ss', time_24hr: true, minuteIncrement: 1 }"
                />
                <small
                  v-for="(validation, index) in validationsCategory.expire_at"
                  :key="`errorexpire_at${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Tujuan :</label>
              <validation-provider>
                <b-form-select
                  v-model="formPayloadCoupon.purpose"
                  :options="purposeCoupon"
                />
                <small
                  v-for="(validation, index) in validationsCategory.purpose"
                  :key="`errorpurpose${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Berlaku berapa hari (Dari tanggal user mendapat kupon) :</label>
              <validation-provider>
                <b-form-input
                    v-model="formPayloadCoupon.expiration_days"
                    type="number"
                    :state="validationsCategory.expiration_days ? false:null"
                    placeholder="Contoh: 1, 2, 5"
                  />
                <small
                  v-for="(validation, index) in validationsCategory.expiration_days"
                  :key="`errorexpirationdays${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer="{}">
        <div v-if="isLoadingCategory">
          <br>
          <b-spinner
            class="mb-2"
            variant="primary"
          /><br>
        </div>
        <b-button
          variant="success"
          :disabled="isLoadingCategory"
          @click="submitItem(), editId == null ? createItem() : updateItem()"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BButton, BSpinner, BFormInput, BFormFile, BFormCheckbox, BFormSelect, BInputGroup, BImg,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import { Money } from 'v-money'

export default {
  components: {
    BButton,
    BSpinner,
    ValidationProvider,
    BFormInput,
    BFormCheckbox,
    BFormFile,
    BFormSelect,
    quillEditor,
    BInputGroup,
    Money,
    flatPickr,
    BImg,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    formPayload: {
      type: '',
    },
    isLoading: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
    createItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    validations: {
      type: '',
    },
    imageCoupon: {
      type: String,
    },
    cleanUpForm: {
      type: Function,
    },
    purpose: {
      type: '',
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      parents: [],
      isLoadingCategory: false,
      formPayloadCoupon: {
        purpose: '',
        name: '',
        type: '',
      },
      purposeCoupon: [],
      validationsCategory: '',
      categoryName: '',
      money: {
        decimal: '',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
    }
  },
  watch: {
    purpose(value) {
      this.purposeCoupon = value
    },
    isLoading(value) {
      this.isLoadingCategory = value
    },
    validations(value) {
      this.validationsCategory = value
    },
    formPayload: {
      handler(value) {
        this.formPayloadCoupon = value
        if (this.editId == null) {
          this.formPayloadCoupon.image = value.image
        }
      },
      deep: true,
    },
    formPayloadCoupon: {
      handler(value) {
        this.$emit('payloadCoupon', value)
      },
      deep: true,
    },
  },
  methods: {
    submitItem() {
      // eslint-disable-next-line eqeqeq
      if (this.formPayloadCoupon.is_active == true) {
        this.formPayloadCoupon.is_active = '1'
      } else {
        this.formPayloadCoupon.is_active = '0'
      }
    },
    changePhoto(event) {
      // eslint-disable-next-line prefer-destructuring
      this.formPayloadCoupon.image = event.target.files[0]
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
