<template>
  <b-card-code
    no-body
    title="Kupon"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">
        <div class="col-md-12">
          <div class="form-row mb-1 justify-content-between">
            <AddCoupon
              :is-loading="isLoading"
              :edit-id="editId"
              :validations="validations"
              :create-item="createItem"
              :update-item="updateItem"
              :clean-up-form="cleanUpForm"
              :form-payload="formPayload"
              :image-coupon="imageCoupon"
              :purpose="purpose"
              @payloadCoupon="getPayload($event)"
            />
            <div class="col-lg-4 mb-lg-0 align-self-end">
              <input
                v-model="filter.keyword"
                type="text"
                placeholder="Search"
                class="form-control form-control-sm"
              >
            </div>
          </div>
          <DetailCoupon
            :result="detail"
          />
        </div>
      </div>
    </div>
    <Coupon
      :result="result"
      :is-loading="isLoading"
      :get-data="getData"
      :edit-item="editItem"
      :delete-item="deleteItem"
      :detail-item="detailItem"
    />
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import AddCoupon from '@/components/Coupon/AddCoupon.vue'
import DetailCoupon from '@/components/Coupon/DetailCoupon.vue'
import Coupon from '@/components/Coupon/Coupon.vue'

Vue.use(VuejsDialog)

export default {
  title() {
    return `List Kupon`
  },
  components: {
    BCardCode,
    AddCoupon,
    Coupon,
    DetailCoupon,
  },
  watch: {
    filter: {
      handler: _.debounce(function (value) {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      result: {},
      isLoading: false,
      validations: {},
      formPayload: Object,
      editId: null,
      categoryName: '',
      parent: {},
      detail: {},
      filter: {
        keyword: '',
      },
      imageCoupon: null,
      purpose: Array,
    }
  },
  created() {
    this.getData()
    this.getPurpose()
  },
  methods: {
    getPayload(value) {
      if (value) {
        this.formPayload = value
      }
    },
    cleanUpForm() {
      this.editId = null
      this.imageCoupon = null
      this.formPayload = {
        name: '',
        is_active: false,
        description: '',
        minimum_transaction: '',
        type: '',
        amount: '',
        maximum_discount: '',
        start_from: '',
        expire_at: '',
        purpose_key: '',
        expiration_days: null,
      }
      this.validations = ''
    },
    detailItem(item) {
      this.detail = item
      this.$bvModal.show('form-detail-modal')
    },
    createItem() {
      this.getPayload()
      const form = this.preparePayload()
      this.isLoading = true

      this.$http.post('/admin/coupons', form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-kupon-modal')
          this.getData()
          successNotification(this, 'Success', 'Kupon berhasil di buat')
          this.cleanUpForm()
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
          }
          this.isLoading = false
        })
    },
    updateItem() {
      const form = this.preparePayload()
      this.isLoading = true
      form.append('_method', 'PATCH')

      this.$http.post(`/admin/coupons/${this.editId}`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-kupon-modal')
          this.getData()
          successNotification(this, 'Success', 'Kupon berhasil di ubah!')
          this.cleanUpForm()
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoading = false
        })
    },
    preparePayload() {
      const form = new FormData()
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      return form
    },
    editItem(item) {
      this.cleanUpForm()
      this.editId = item.uuid
      this.imageCoupon = item.image
      this.formPayload = {
        name: item.name,
        is_active: item.is_active,
        description: item.description,
        minimum_transaction: item.minimum_transaction,
        type: item.type,
        amount: item.amount,
        maximum_discount: item.maximum_discount,
        start_from: item.start_from,
        expire_at: item.expire_at,
        purpose: item.purpose_key,
        expiration_days: item.expiration_days,
      }
      console.log('EDIT');
      console.log(item)
      this.$bvModal.show('form-kupon-modal')
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this kupon?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`admin/coupons/${id}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Kupon berhasil di hapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    getPurpose() {
      this.$http.get('/admin/coupons-purpose')
        .then(response => {
          const result = response.data.data
          for (let i = 0; i < result.length; i++) {
            result[i].value = result[i].key
            result[i].text = result[i].label
            delete result[i].key
            delete result[i].label
          }
          this.purpose = result
          this.purpose.unshift({ value: '', text: 'Pilih Tujuan' })
          // this.isLoading = false
        })
    },
    getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/coupons', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
