<template>
  <div>
    <b-modal
      id="form-detail-modal"
      no-close-on-backdrop
      size="lg"
      title="Detail Kupon"
    >
      <b-row>
        <b-col
          cols="21"
          xl="12"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start mb-2">
            <b-avatar
              :src="result.image"
              :text="result.name != null ? result.name.charAt(0) : '-'"
              :variant="`light-danger`"
              size="104px"
              rounded
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          xl="12"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Name</span>
              </th>
              <td class="pb-50">
                {{ result.name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Status Kupon</span>
              </th>
              <td class="pb-50">
                <span
                  class="badge text-capitalize badge-pill"
                  :class="
                    result.is_active == true
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  <feather-icon
                    :icon="result.is_active == true ? 'CheckCircleIcon' : 'XCircleIcon'"
                    class="mr-75"
                  />
                </span>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="DollarSignIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Minimum Transaksi</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.minimum_transaction | formatAmount }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="FlagIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Tipe</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ result.type }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CreditCardIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Nilai Kupon</span>
              </th>
              <td class="pb-50">
                {{ result.amount }}{{ result.amount_type == 'percent' ? '%' : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="DivideIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Maksimal Diskon</span>
              </th>
              <td class="pb-50">
                {{ result.maximum_discount | formatAmount }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="FastForwardIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Berlaku Mulai</span>
              </th>
              <td class="pb-50">
                {{ result.start_from }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="SkipBackIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Berlaku Sampai</span>
              </th>
              <td class="pb-50">
                {{ result.expire_at }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Dibuat Pada</span>
              </th>
              <td class="pb-50">
                {{ result.created_at }}
              </td>
            </tr>
          </table>
          <div>
            <h6 class="mt-2">
              Deskripsi
            </h6>
            <div v-html="result.description"></div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol, BAvatar, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BAvatar,
    BRow,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['result'],
}
</script>
